<template>
  <v-main id="login-page">
    <v-container>
      <v-row no-gutters justify="center">
        <v-col cols="12" md="6">
          <div class="login-card">
            <!-- Login -->
            <v-card class="pa-4 mobile__py__25px" v-if="LoginStep">
              <v-card-text class="pa-0">
                <h3 class="mt-0 mb-5 black--text">تسجيل الدخول</h3>
                <div class="form-fields">
                  <v-form ref="loginform" method="post">
                    <h6 class="font-weight-bold pb-1">رقم الهاتف</h6>
                    <vue-tel-input-vuetify
                      class="phonenumber-field"
                      ref="phoneNumberInput"
                      v-model="phoneNumber"
                      label="ادخل رقم الجوال"
                      :only-countries="['SA', 'EG', 'SD']"
                      default-country="SA"
                      placeholder=""
                      :filled="true"
                      required
                      suffix="+966"
                      :rules="[
                        (v) =>
                          (v && !!isValidPhone) || 'من فضلك تأكد من رقم الهاتف'
                      ]"
                      :valid-characters-only="true"
                      :validate-on-blur="true"
                      hide-details="auto"
                      @validate="onValidatePhoneNumber"
                      @input="onInputPhoneNumber($event, 'phoneNumber')"
                    >
                    </vue-tel-input-vuetify>

                    <div class="password-field">
                      <h6 class="font-weight-bold pb-1" for="phone-no">
                        كلمة المرور
                      </h6>
                      <v-text-field
                        data-test="password"
                        ref="password"
                        v-model="password"
                        :rules="[() => !!password || '']"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="showPassword ? 'text' : 'password'"
                        label="كلمة المرور"
                        required
                        filled
                        hide-details="auto"
                        @keydown.enter="validateLoginForm"
                        @click:append="showPassword = !showPassword"
                        @input="onInputPhoneNumber($event, 'password')"
                      />
                    </div>
                  </v-form>
                  <v-flex d-flex justify-space-between align-self-center>
                    <div class="d-flex align-self-center">
                      <v-checkbox
                        class="ma-0 pa-0"
                        hide-details="auto"
                        v-model="remember"
                      ></v-checkbox>
                      <h6 class="ma-0">تذكرني</h6>
                    </div>

                    <v-btn
                      small
                      class="forget-password-link font-weight-bold"
                      text
                      color="info"
                      data-test="forgetPassword"
                      @click="passwordSteper"
                      >نسيت كلمة المرور؟
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      data-test="login"
                      :loading="spinnerLoading"
                      large
                      block
                      class="my-4"
                      color="info"
                      @click="validateLoginForm"
                      ><span class="tf">تسجيل دخول</span></v-btn
                    >
                    <v-btn
                      large
                      data-test="signUp"
                      block
                      class="ma-0"
                      color="green"
                      @click="registrationStep"
                    >
                      <span class="tf"> إنشاء حساب جديد</span>
                    </v-btn>
                  </v-flex>
                </div>
              </v-card-text>
            </v-card>
            <!-- registration -->
            <v-card class="pa-4 mobile__py__25px" v-else-if="registration">
              <v-card-text class="pa-0">
                <div class="d-flex align-center">
                  <div
                    @click="goBackStep"
                    data-test="backStep"
                    v-show="stepName === 'step3'"
                    class="register-step-4__back"
                  >
                    <GSvg
                      class="register-step-4__back__icon"
                      title="back"
                      nameIcon="back-arrow"
                    />
                  </div>
                  <h2 class="mt-0 mb-5 black--text mr-1">تسجيل حساب جديد</h2>
                </div>
                <div class="d-flex w-100">
                  <v-progress-circular
                    v-if="!companyLogo.length && isPageLoading"
                    :size="50"
                    color="primary"
                    indeterminate
                    class="mx-auto"
                  ></v-progress-circular>
                  <v-img
                    v-else-if="companyLogo"
                    class="companyLogo borde-gradient-purpler mx-auto"
                    max-width="100"
                    min-width="100"
                    :src="companyLogo"
                    :lazy-src="companyLogo"
                  />
                </div>
                <RegisterWizard
                  ref="registerComponent"
                  :query="query"
                  @backToLogin="backToLogin"
                  @stepName="stepName = $event"
                ></RegisterWizard>
              </v-card-text>
            </v-card>
            <!-- password -->
            <v-card class="pa-4 mobile__py__25px" v-else-if="passwordStep">
              <v-card-text class="pa-0">
                <h2 class="mt-0 mb-5 black--text">استعادة كلمة المرور</h2>
                <ForgetPasswrodWizard @showLoginStep="firstStep" />
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- Enviroments Modal -->
    <EnvsModal
      @changeDialogState="envsModal = $event"
      @closed-add-dialog="selectedEnvClick"
      v-bind="envModalProps"
    />
  </v-main>
</template>

<script>
import { EventBus } from '@/services/EventBus'
import { userService } from '../services'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import '@/styles/material-dashboard/_login.scss'
import { required } from 'vuelidate/lib/validators'
import RegisterWizard from '@/components/login-register/RegisterWizard.vue'
import ForgetPasswrodWizard from '@/components/login-register/ForgetPasswrodWizard.vue'
import EnvsModal from '@/components/modals/EnvsModal.vue'
import { convertNumbers2English } from '../utils/formatters'

export default {
  name: 'Login-Register',
  components: {
    ForgetPasswrodWizard,
    RegisterWizard,
    EnvsModal
  },
  data() {
    return {
      EnvsModalBtnLoading: false,
      isPageLoading: true,
      companyLogo: '',
      phoneNumber: '',
      password: '',
      tab: 0,
      errorMessages: 'Incorrect login info',
      color: 'general',
      showPassword: false,
      finalModel: {},
      phoneData: {},
      submitted: false,
      hasEnvs: true,
      envsModal: false,
      envs: [],
      query: this.$route.query,
      LoginStep: true,
      registration: false,
      passwordStep: false,
      remember: false,
      stepName: ''
    }
  },
  created() {
    EventBus.$on('setActiveTab', (options) => {
      this.tab = options.tab
      this.phoneNumber = options.phone
    })
    EventBus.$on('backToLogin', (options) => {
      this.firstStep()
      this.tab = options.tab
      this.phoneNumber = options.phone
    })
    this.phoneNumber = this.query.phoneNumber ?? ''
  },
  beforeDestroy() {
    this.setSpinnerLoading(false)
  },
  mounted() {
    this.isPageLoading = false
    if (this.validePhonenumber) {
      this.phoneNumber = this.validePhonenumber
    }
    if (this.query.phone) {
      this.tab = 1
      this.phoneNumber = this.query.phone
    }
    if (this.$route.query.auth && this.$route.query.auth === 'register') {
      this.registrationStep()
      this.handleSetPhoneNumber()
    } else {
      this.firstStep()
    }
    this.tab = this.activeLignTab
  },
  watch: {
    activeLignTab(val) {
      this.tab = val
    }
  },
  computed: {
    ...mapState('accountState', ['logInPopUp', 'currentEnv']),
    ...mapState('userState', [
      'spinnerLoading',
      'validePhonenumber',
      'activeLignTab'
    ]),
    ...mapGetters('accountState', ['isCustomer']),
    envModalProps() {
      return {
        dialogState: this.envsModal,
        envsData: this.filterdEnvData,
        btnLoading: this.EnvsModalBtnLoading
      }
    },
    filterdEnvData() {
      return this.envs.filter((env) => {
        return env.employeeRole || env.customerRegistered
      })
    },
    isValidPhone() {
      return this.phoneData.valid ? this.phoneData.valid : false
    },
    getCustomerDefaultRole() {
      return (
        JSON.parse(localStorage.getItem('activeCustomerRole')) ||
        this.currentEnv?.role[0]
      )
    }
  },

  validations: {
    phoneNumber: {
      required
    },
    password: {
      required
    },
    form: ['phoneNumber', 'password']
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    ...mapActions('accountState', [
      'login',
      'checkPhoneNumber',
      'confirmPhoneNumber',
      'getUserEnvs',
      'getCompanyImages',
      'getContractsStatuses',
      'getCurrentEnvData'
    ]),
    ...mapMutations('accountState', [
      'otpConfirmed',
      'loginSuccess',
      'loginFailure',
      'setCurrentEnv',
      'setConfigrations',
      'loginRequest',
      'setCustomerRole'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('appState', ['addNotification', 'setSync', 'setCoupon']),

    handleSetPhoneNumber() {
      const { phoneNumber } = this.$route?.query
      if (!phoneNumber) {
        return
      }
      this.$nextTick(() => {
        this.$refs.registerComponent.$refs.step1.phoneNumber = phoneNumber
        this.$refs.registerComponent.$refs.step1.$refs.regPhoneNumber.readonly = true
      })
    },
    onInputPhoneNumber(str, key) {
      this[key] = convertNumbers2English(str)
    },

    // /--------------------steper---------------///
    firstStep() {
      this.registration = false
      this.LoginStep = true
      this.passwordStep = false
    },
    registrationStep() {
      this.registration = true
      this.LoginStep = false
      this.passwordStep = false
    },
    passwordSteper() {
      this.registration = false
      this.LoginStep = false
      this.passwordStep = true
    },
    async selectedEnvClick(currentEnv) {
      this.EnvsModalBtnLoading = true
      localStorage.setItem('currentEnv', JSON.stringify(currentEnv))
      this.setCurrentEnv(currentEnv)
      // set Customer Role
      if (this.isCustomer) {
        this.setCustomerRole(this.getCustomerDefaultRole)
      }
      // to get full data of selected environment
      await this.getCurrentEnvData(currentEnv._id)
      this.$router
        .push(
          this.$route.query.redirectFrom ||
            `/dashboard?isCustomer=${this.isCustomer}`
        )
        .then(() => {
          this.envsModal = false
          this.EnvsModalBtnLoading = false
        })
      this.getCompanyImages(currentEnv._id)
    },
    goBackStep() {
      this.$refs.registerComponent.$refs.regWizard.prevTab()
      this.stepName = ''
    },
    validateStep(name) {
      const refToValidate = this.$refs[name]
      return refToValidate.validate()
    },

    collectData(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model)
      }
    },

    // Login Form -------------------------------------
    validateLoginForm() {
      this.$v.form.$touch()
      this.setSpinnerLoading(true)

      if (this.$v.form.$invalid) {
        this.addNotification({
          text: 'من فضلك تأكد من البيانات',
          color: 'error'
        })
        this.setSpinnerLoading(false)
      } else {
        this.handleLoginFormSubmit()
      }
    },
    // eslint-disable-next-line consistent-return
    async getUserSubscription(newData) {
      try {
        await this.loginSuccess(newData)
        const { subscription } = await this.$store.dispatch(
          'accountState/getUserSubscription'
        )
        localStorage.setItem('subscription', JSON.stringify(subscription))
        this.$router.push(this.$route.query.redirectFrom || `/dashboard`)
      } catch {}
    },
    async getEnvironment(user) {
      try {
        const { data } = await userService.userEnvironment(user._id, user.token)
        if (data.user) {
          // Check if the user is active or not
          if (data.user.active === 0) {
            this.addNotification({
              text: 'تم تعطيل الحساب! الرجاء مراجعة خدمة العملاء',
              color: 'error'
            })
            localStorage.removeItem('tokenData')
            localStorage.removeItem('logged_in')
            return false
          }

          // If user is not registered yet then redirect toK registration
          if (!data.user.registered) {
            this.addNotification({
              text: 'بيانات الحساب غير مكتملة، الرجاء إكمال البيانات لإكمال عملية التسجيل',
              color: 'success'
            })
            return this.registrationStep()
          }

          // If user is type is seeker redirect to registration
          if (data.user.type === 'seeker' || data.user.type === 'marketer') {
            localStorage.setItem('should-update', JSON.stringify(data.user))
            this.addNotification({
              text: 'يوجد لديك حساب مسبقا بناءا على تسجيلك في هكتار، يرجى إكمال بيانات هكتار بلس لإكمال التسجيل',
              color: 'success'
            })
            return this.registrationStep()
          }

          const oldData = JSON.parse(localStorage.getItem('tokenData'))
          const newData = Object.assign({}, oldData, data.user)
          localStorage.setItem('tokenData', JSON.stringify(newData))
          const envs = data.user.environments || []

          const filteredEnvs = envs.filter((env) => {
            return env.employeeRole || env.customerRegistered
          })

          // If there is no environment redirect to env setup
          const types = ['company', 'office'].includes(data.user.type)
          if (envs.length === 0 && types) {
            this.addNotification({
              text: 'يوجد لديك حساب مسجّل لدينا مسبقا، يرجى إكمال بيانات هكتار بلس لإكمال التسجيل',
              color: 'success'
            })
            return this.$router.push('/setup-env')
          }

          if (
            [...filteredEnvs].length === 1 &&
            !(
              filteredEnvs[0].customerRegistered && filteredEnvs[0].employeeRole
            )
          ) {
            const currentEnv = filteredEnvs[0]
            this.setCurrentEnv(currentEnv)
            localStorage.setItem('currentEnv', JSON.stringify(currentEnv))
            this.getUserSubscription(newData)
            // to get full data of selected environment
            this.getCurrentEnvData(currentEnv._id)
            this.getCompanyImages(currentEnv._id)
            this.getContractsStatuses()
          }
          if (
            filteredEnvs.length > 1 ||
            (envs[0].customerRegistered && envs[0].employeeRole)
          ) {
            this.envs = envs
            this.envsModal = true
            this.getUserSubscription(newData)
          }

          // set Customer Role
          this.isCustomer && this.setCustomerRole(this.getCustomerDefaultRole)

          this.addNotification({
            text: `أهلا بك ${data.user.name} ، تم تسجيل دخولك بنجاح!`,
            color: 'success'
          })
        }

        // If no user found ot the type of user is  seeker then show the user registration form to complete
        if (!data.user) {
          return this.registrationStep()
        }
        // Commit the configs
        return this.checkForConfig()
      } catch (error) {
        this.addNotification({
          text: error,
          color: 'error'
        })
      }
    },
    async handleLoginFormSubmit() {
      try {
        this.submitted = true
        const phoneNumber = this.phoneNumber
          .split(' ')
          .join('')
          .replace('0', '966')
        const password = this.password

        if (phoneNumber && password) {
          const { data } = await this.login({ phoneNumber, password })
          const user = data.user
          if (user.token) {
            await this.loginRequest(user)
            this.getEnvironment(user)
          }
        }
      } catch (error) {
        this.loginFailure(error)
        let message = 'من فضلك تأكد من رقم جوالك وكلمة المرور'
        if (error?.response?.data?.error === 'deactivated') {
          message = 'تم تعطيل الحساب من قبل الإدارة'
        }
        this.addNotification({
          text: message,
          color: 'error'
        })
      } finally {
        this.setSpinnerLoading(false)
      }
    },
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    checkForConfig() {
      const config = !!JSON.parse(localStorage.getItem('configrations'))
      if (!config) {
        this.$store.dispatch('accountState/getConfigrations').then((res) => {
          localStorage.setItem('configrations', JSON.stringify(res))
        })
      } else {
        const types = JSON.parse(localStorage.getItem('configrations'))
        this.$store.commit('accountState/setConfigrations', types)
      }
    },
    backToLogin() {
      this.registration = false
      this.LoginStep = true
    }
  },
  metaInfo() {
    return {
      title: 'تسجيل الدخول'
    }
  }
}
</script>

<style lang="scss" scoped>
.register-step-4__back {
  cursor: pointer;

  &__icon {
    transform: scale(-1);
    height: 2rem;
    width: 1.9rem;
  }
}
</style>
